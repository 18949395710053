<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <p v-if="fromProtocole" class="h3">
            Impossible de modifier un protocole en cours d'utilisation.</p>
          <p v-else class="h3">Vous n'avez pas les droits pour consulter cette page.</p>
        </Container>
      </Section>
    </div>

    <div v-if="fromProtocole" class="view-footer">
      <div class="view-actions">
        <Btn
          class="action-btn"
          icon="calendar"
          color="primary"
          text="Retour aux protocoles d'essai"
          :to="{
            name: 'protocols'
          }"
        />
      </div>
    </div>
    <div v-else class="view-footer">
      <div class="view-actions">
        <Btn
          class="action-btn"
          icon="home"
          color="primary"
          text="Retour à l'accueil"
          :to="{
            name: 'home'
          }"
          />
      </div>
    </div>
  </div>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'NotFoundView',

  components: {
    Container,
    Section,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      fromProtocole: false,
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params && to.params.fromProtocole) {
        // eslint-disable-next-line no-param-reassign
        vm.fromProtocole = true
      }
    })
  },
}
</script>
